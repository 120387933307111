import React, {useEffect} from 'react';
import '../css/Information.css';
import '../css/API.css';
import BaseView from "../components/BaseView";
import {Card, Col, Row, List, Button} from "antd";
import {Link} from "react-router-dom";
import Typewriter from "../components/TypeWriter";
import {CheckCircleOutlined} from '@ant-design/icons';

function API2() {

    // set document title
    useEffect(() => {
        document.title = "StocksEyes API";
    }, []);

    const lines = [
        "Get Real Time Price, volume, OI, Depth Data",
        "Get Historical Data",
        "Get Real Time Candlestick Data",
        "Get last N candles Real Time"
    ];
    const listItems = ['Simple & Easy', 'Fast & Robust', 'No Rate Limits', "Build for Scale"];

    const ListRepresentation = (<div>
        <List
            dataSource={listItems}
            renderItem={(item) => (
                <List.Item style={{fontSize: '20px'}}>
                    <CheckCircleOutlined style={{fontSize: '24px', color: 'green', marginRight: '8px'}}/>
                    {item}
                </List.Item>
            )}
        />
    </div>);


    return (
        <BaseView>
            <div>
                <div style={{margin: "40px"}}>
                    <Typewriter lines={lines}/>
                </div>
                <div className={"api-option-margin"}>
                    <Row gutter={[128, 16]} justify="center">
                        <Col xs={24} sm={12} md={8} lg={8} xl={8} style={{textAlign: 'center'}} key={"WEB SOCKETS"}>

                            <Card
                                hoverable={true}
                                cover={
                                    <img
                                        height="250px"
                                        alt={"Web Sockets"}
                                        src={"https://websockets.readthedocs.io/en/stable/_static/websockets.svg"}
                                    />
                                }
                            >
                                <h1>Web Sockets</h1>
                                <h3>Ride the Market's Pulse – Real-time Data for Swift, Smart Trades. Real-time updates
                                    for high-frequency precision. Elevate your trades with instant data, anytime,
                                    anywhere.</h3>

                                <h4 style={{color: "green"}}>For businesses: Integrate directly on the client side and
                                    cut down on hefty server expenses with real-time updates.</h4>

                                {ListRepresentation}

                                <h2>Free to Test</h2>

                                <h3>Starting from as low as $2.5/month for 1M requests </h3>

                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <a rel="noreferrer" href="https://github.com/stockseyes/code-samples"
                                       target="_blank"><h2><br/>Checkout our code samples on Github</h2></a>
                                    <a rel="noreferrer" href="https://github.com/stockseyes/code-samples"
                                       target="_blank">
                                        <img
                                            src="https://s3.studytonight.com/curious/uploads/pictures/1632240644-106730.jpeg"
                                            width="215" alt="GITHUB LOGO"/>
                                    </a>
                                </div>


                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <a rel="noreferrer" href="https://www.npmjs.com/package/@stockseyes/market-pulse"
                                       target="_blank"><h2>Checkout our NPM package</h2></a>
                                    <a rel="noreferrer" href="https://www.npmjs.com/package/@stockseyes/market-pulse"
                                       target="_blank">
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/db/Npm-logo.svg/1200px-Npm-logo.svg.png"
                                            width="180" alt="NPM LOGO"/>
                                    </a>
                                </div>
                                <Link to={"/contact"}>
                                    <div style={{margin: "10px"}}>
                                        <br/>
                                        <Button type="primary" size={"large"} shape={"round"} block
                                                style={{paddingBottom: "50px"}}>
                                            <h2>Contact us to get your quote</h2>
                                        </Button>
                                    </div>
                                </Link>
                            </Card>
                        </Col>


                        <Col xs={24} sm={12} md={8} lg={8} xl={8} style={{textAlign: 'center'}} key={"REST API"}>
                            <a
                                rel="noreferrer" href={"https://rapidapi.com/singhaltushar903/api/stockseyes"}
                                target="_blank">
                                <Card
                                    hoverable={true}
                                    cover={
                                        <img
                                            height="250px"
                                            alt={"Rest API"}
                                            // src={"https://www.trisotech.com/wp-content/uploads/restAPI-Header.jpg"}
                                            src={"https://keenethics.com/wp-content/uploads/2022/01/rest-api-1.svg"}
                                        />
                                    }
                                >
                                    <h1>Rest API</h1>
                                    <h3>Unlock Stock Insights at Your Pace – Tailored for Strategic Gains. Harness stock
                                        data at your pace. Ideal for medium to low-frequency trading, strategic gains,
                                        and
                                        long-term insights.</h3>
                                    {ListRepresentation}

                                    <h2>Free to Test</h2>

                                    <h3>Starting from as low as $5/month</h3>

                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <a rel="noreferrer" href="https://rapidapi.com/singhaltushar903/api/stockseyes"
                                           target="_blank">
                                            <img
                                                src="https://storage.googleapis.com/rapidapi-documentation/connect-on-rapidapi-light.png"
                                                width="215" alt="Connect on RapidAPI"/>
                                        </a>
                                    </div>
                                </Card>
                            </a>
                        </Col>
                    </Row>
                </div>
            </div>
        </BaseView>
    );
}

export default API2;
