import {Button, Form, Input, message, Select, Spin, Upload} from 'antd';
import React from 'react';
import {deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import {EditorState, convertToRaw} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../css/addBlog.css"
// import {getUserId} from "@singhaltushar903/firebase-authentication";
import {getUserId, saveMessage} from "../external/firebase";
import {blogStorage} from "../config/stockEyesConfig";
import TopHeader from "../components/TopHeader";
import Footer from "../components/Footer";


const blogCategories = ["Hello"]


const {Option} = Select;

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

class AddBlog extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            coverImageFileList: [],
            audioFileList: [],
            editorState: EditorState.createEmpty(),
            showRichTextEditor: true,
            textCount: 0
        }
    }

    setCoverImageFileList = async (coverImageFileList) => {
        this.setState({coverImageFileList})
    }

    onChangeCoverImageFileList = ({fileList: newCoverImageFileList}) => {
        this.setState({coverImageFileList: newCoverImageFileList})
    };

    onPreviewImage = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    setAudioFileList = async (audioFileList) => {
        this.setState({audioFileList})
    }

    onChangeAudioFileList = ({fileList: newAudioFileList}) => {
        this.setState({audioFileList: newAudioFileList})
    };


    onChangeI = (e) => {
        this.setState({
            textCount: e.target.value.replace(/\s+/g, ' ').trim().length
        })
    }

    convert = (html) => {
        const divContainer = document.createElement("div");
        divContainer.innerHTML = html;
        return divContainer.textContent || divContainer.innerText || "";
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
            textCount: this.convert(draftToHtml(convertToRaw(editorState.getCurrentContent()))).replace(/\s+/g, ' ').trim().length
        });
    };

    switchTextEditor = () => {
        let currentEditorstate = this.state.showRichTextEditor;
        this.setState({
            showRichTextEditor: !currentEditorstate
        })
    }

    showErrorMessage = async (content) => {
        await message.error({
            content,
            style: {marginTop: "120px"}
        });
    }


    uploadFile = async (file) => {
        let fileRef = null;
        const filePath = `${getUserId()}/${Date.now() + file.name}`;
        fileRef = ref(getStorage(), filePath);
        await uploadBytesResumable(fileRef, file.originFileObj);
        let publicImageUrl = await getDownloadURL(fileRef);
        return {fileRef, publicImageUrl}
    }

    onFinish = async ({embedCode, keywords, articleName, subHeading, description, categories, youtubeVideoLink}) => {

        // override description if rich text editor is used
        if (this.state.showRichTextEditor) {
            description = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
            if (this.state.textCount < 800) {
                await this.showErrorMessage("Blog content should not be less than 800 characters");
                return;
            }
        } else {
            // Replace new lines with br tag in description
            description = description.split("\n").join("<br/>");
        }

        if (!embedCode && this.state.coverImageFileList.length === 0) {
            await this.showErrorMessage("one of the embedCode or coverImage needs to be present")
            return;
        }

        this.setState({
            loading: true
        });


        const coverImageDetails = await this.uploadFile(this.state.coverImageFileList[0])
        let coverImageUrl = coverImageDetails.publicImageUrl;
        let coverImageRef = coverImageDetails.fileRef;

        const audioDetails = await this.uploadFile(this.state.audioFileList[0])
        let audioUrl = audioDetails.publicImageUrl;
        let audioRef = audioDetails.fileRef;


        try {
            await saveMessage(blogStorage.path, {
                date: Date.now(),
                embedCode: embedCode ? embedCode : null,
                coverImage: coverImageUrl,
                audio: audioUrl,
                keywords,
                articleName,
                subHeading: subHeading ? subHeading : null,
                description,
                categories,
                isProcessed: false,
                writerId: getUserId(), // TODO : Fix this
                isPlagiarismChecked: false,
                youtubeVideoLink: youtubeVideoLink
            });

            message.info({
                content: "Your Blog is submitted",
                style: {marginTop: "120px"}
            });

            this.formRef.current.resetFields();
            this.setState({
                coverImageFileList: [],
                audioFileList: [],
                editorState: EditorState.createEmpty(),
                textCount: 0
            });

        } catch (e) {
            // article submission has failed due to a error
            message.error({
                content: `Your Blog is not submitted due to an error. Please send photo to developer. Error: ${e.message} `,
                style: {marginTop: "120px"}
            });
            // clean uploaded coverImage if any
            if (coverImageRef) {
                await deleteObject(coverImageRef);
            }
            if (audioRef) {
                await deleteObject(audioRef);
            }
        } finally {
            this.setState({
                loading: false
            });
        }
    };


    render() {

        const blogCategoryOptions = [];
        for (const category of blogCategories) {
            blogCategoryOptions.push(<Option key={category}>{category}</Option>);
        }

        return (
            <div>
                <TopHeader/>
                <div style={{margin: "5px", marginTop: "50px"}}>

                    <Spin spinning={this.state.loading}>

                        <Form {...layout} ref={this.formRef} name="control-hooks" onFinish={this.onFinish}>
                            <Form.Item
                                label="Upload Cover Image"
                            >
                                <Upload
                                    beforeUpload={async (file) => {
                                        await this.setCoverImageFileList([...this.state.coverImageFileList, file]);
                                        return false;
                                    }}
                                    listType="picture-card"
                                    coverImageFileList={this.state.coverImageFileList}
                                    onChange={this.onChangeCoverImageFileList}
                                    onPreview={this.onPreviewImage}
                                >
                                    {this.state.coverImageFileList.length < 1 && '+ Upload'}
                                </Upload>
                            </Form.Item>

                            <Form.Item
                                name="articleName"
                                label="Title / Blog Name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input autoComplete="off" placeholder="Please enter an attractive article name"/>
                            </Form.Item>

                            <Form.Item
                                name="youtubeVideoLink"
                                label="Youtube Video Link"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input autoComplete="off" placeholder="Please enter corresponding youtube video link"/>
                            </Form.Item>


                            <Form.Item
                                label="Upload Audio Transcript"
                            >
                                <Upload
                                    beforeUpload={async (file) => {
                                        await this.setAudioFileList([...this.state.audioFileList, file]);
                                        return false;
                                    }}
                                    listType="picture-card"
                                    audioFileList={this.state.audioFileList}
                                    onChange={this.onChangeAudioFileList}
                                >
                                    {this.state.audioFileList.length < 1 && '+ Upload'}
                                </Upload>
                            </Form.Item>

                            <Form.Item
                                name="subHeading"
                                label="Sub Heading"
                            >
                                <Input autoComplete="off"
                                       placeholder="Optional subheading to describe article in few words"/>
                            </Form.Item>

                            {
                                this.state.showRichTextEditor ?
                                    <div>
                                        <h2 style={{textAlign: "center"}}>Blog Content</h2>
                                        <div style={{textAlign: "center"}}><Button type="primary"
                                                                                   onClick={this.switchTextEditor}>Switch
                                            to
                                            old text editor</Button></div>

                                        <div className="blogEditorWrapper">

                                            <div className="blogEditor">
                                                <Editor
                                                    editorState={this.state.editorState}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={this.onEditorStateChange}
                                                />
                                            </div>

                                        </div>
                                        <p style={{textAlign: "center"}}> Character count: {this.state.textCount}</p>
                                    </div>
                                    :
                                    <div>
                                        <div style={{textAlign: "center"}}><Button type="primary"
                                                                                   onClick={this.switchTextEditor}>Switch
                                            to
                                            rich text editor</Button></div>
                                        <Form.Item
                                            name="description"
                                            label="Blog Content"
                                            rules={[
                                                {
                                                    required: true,
                                                    type: "string"
                                                },
                                            ]}
                                        >
                                            <Input.TextArea
                                                onChange={this.onChangeI}
                                                style={{whiteSpace: "pre-wrap"}}
                                                autoComplete="off"
                                                autoSize={{minRows: 3, maxRows: 20}}
                                                showCount
                                                placeholder="Please write a short description in your own words. Do not copy paste from anywhere. Read from anywhere but write in your own words"/>
                                        </Form.Item>
                                        <p style={{textAlign: "center"}}> Character count: {this.state.textCount}</p>
                                    </div>

                            }


                            <Form.Item
                                name="keywords"
                                label="Keywords"
                                rules={[
                                    {
                                        required: true
                                    },
                                ]}
                            >
                                <Input autoComplete="off"
                                       placeholder="Please enter comma separated keywords with which users tend to search your article"/>
                            </Form.Item>

                            <Form.Item
                                name="categories"
                                label="Categories"
                                rules={[
                                    {
                                        required: true
                                    },
                                ]}
                            >
                                <Select
                                    mode="tags"
                                    size="medium"
                                    placeholder="Please select"
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    {blogCategoryOptions}
                                </Select>
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>

                        </Form>
                    </Spin>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default AddBlog;
