import React from 'react';
import '../css/MovingText.css';

const MovingText = ({ text }) => {
    return (
        <div className="moving-text-container">
            <div className="moving-text">
                {text}
            </div>
        </div>
    );
};

export default MovingText;
