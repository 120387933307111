/**
 * To find your Firebase config object:
 *
 * 1. Go to your [Project settings in the Firebase console](https://console.firebase.google.com/project/_/settings/general/)
 * 2. In the "Your apps" card, select the nickname of the app for which you need a config object.
 * 3. Select Config from the Firebase SDK snippet pane.
 * 4. Copy the config object snippet, then add it here.
 */


const firebaseConfig = {
    apiKey: "AIzaSyBAS2MuKU2tmgwDTG3Zxw-NgYKJ38es_QY",
    authDomain: "stockeyes-c9705.firebaseapp.com",
    projectId: "stockeyes-c9705",
    storageBucket: "stockeyes-c9705.appspot.com",
    messagingSenderId: "320540998409",
    appId: "1:320540998409:web:5a0eda9d4c8c155e6831d8",
    measurementId: "G-DXFHVSYRJF"
};

export default firebaseConfig;