import React, {useEffect, useState} from 'react';
import '../css/Information.css';
import Information from "../components/Information";
import {Button, InputNumber, message, Select} from "antd";
import {DownloadOutlined} from '@ant-design/icons';
import {getAllDataOfCollectionPath, getCandleStickData} from "../external/firebase";
import {downloadFile} from "../commons/excelDownload";

function CandleData() {

    const [options, setOptions] = useState([]);
    const [stockSymbol, setStockSymbol] = useState();
    const [timeFrame, setTimeFrame] = useState();
    const [periods, setPeriods] = useState(14);
    const [downloadButtonLoading, setDownloadButtonLoading] = useState(false);

    const getAllStockSymbols = async () => {
        const data = await getAllDataOfCollectionPath("candles")
        console.log(data);
        setOptions(data.map(d => {
            return {
                value: d.id,
                label: d.id
            }
        }));
    }

    // set document title
    useEffect(() => {
        document.title = "Candle Data Download";
    }, []);

    useEffect(() => {
        getAllStockSymbols();
    }, [])

    const exportToCsv = async (e) => {
        if (!stockSymbol) {
            message.error("Please select Stock Symbol")
            return;
        }

        if (!timeFrame) {
            message.error("Please select Time Frame");
            return;
        }

        if (!periods) {
            message.error("Please enter number of candles/periods to download")
            return
        }

        setDownloadButtonLoading(true);

        e.preventDefault()

        // Headers for each column
        let headers = ['startTime, endTime, open,low,high,close,volume']

        // get the data from firebase for the specified trading symbol and time period.
        const candleData = await getCandleStickData(stockSymbol, timeFrame, periods);

        // Convert users data to a csv
        let candleCsv = candleData.reduce((csvData, candle) => {
            const {startTime, endTime, open, low, high, close, volume} = candle
            csvData.push([startTime.toDate().toString(), endTime.toDate().toString(), open, low, high, close, volume].join(','))
            return csvData
        }, [])

        downloadFile({
            data: [...headers, ...candleCsv].join('\n'),
            fileName: `candles_${stockSymbol}_${timeFrame}_${periods}.csv`,
            fileType: 'text/csv',
        })

        setDownloadButtonLoading(false);
    }

    const onChangeStockSymbol = (value) => {
        console.log("Stock symbol" + value)
        setStockSymbol(value);
    }

    const onChangeTimeFrame = (value) => {
        console.log("Time Frame" + value)
        setTimeFrame(value);
    }

    const onChangePeriods = (value) => {
        setPeriods(value);
        console.log('periods', value);
    };


    return (
        <Information title={"OHLC & volume Live candle download "}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Select
                    onChange={onChangeStockSymbol}
                    showSearch
                    style={{
                        width: 500,
                    }}
                    loading={options.length === 0}
                    size="large"
                    placeholder="Search Stock Symbol"
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={options}
                />
            </div>

            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Select
                    onChange={onChangeTimeFrame}
                    showSearch
                    style={{
                        width: 500,
                        marginTop: "20px"
                    }}
                    size="large"
                    placeholder="Select Time frame"
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                    options={[
                        {
                            value: "1m",
                            label: "1 minute"
                        },
                        {
                            value: "5m",
                            label: "5 minute"
                        },
                        {
                            value: "15m",
                            label: "15 minute"
                        },
                        {
                            value: "1h",
                            label: "1 hour"
                        },
                    ]}
                />
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: "20px"}}>
                <p style={{paddingTop: "10px", marginRight: "10px", fontWeight: "bold"}}>Select number of
                    candles/periods</p>
                <InputNumber min={1} max={200} defaultValue={14} onChange={onChangePeriods}/>
            </div>

            <div style={{display: 'flex', justifyContent: 'center', marginTop: "20px"}}>
                <Button type="primary" shape="round" icon={<DownloadOutlined/>} size={"large"} onClick={exportToCsv} loading={downloadButtonLoading}>
                    Download
                </Button>
            </div>
            <div style={{
                marginTop: "20px"
            }}>
                <p>Stay ahead of the market trend with real-time stock data updates on stockeyes.com. Our website offers
                    accurate and reliable candle data for various stock symbols in CSV or Excel format, including open,
                    low, high, close and volume information. Optimize your investment decisions with up-to-the-minute
                    data available at the time of download. Download now on stockeyes.com.</p>
            </div>
        </Information>
    );
}

export default CandleData;
