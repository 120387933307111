import {initializeApp} from 'firebase/app';
import {getAnalytics} from "firebase/analytics";
import {
    doc,
    getFirestore,
    query,
    collection,
    where,
    orderBy,
    onSnapshot,
    limit,
    getDocs,
    getDoc,
    addDoc,
    serverTimestamp
} from 'firebase/firestore';
import {getStorage, uploadBytesResumable, getDownloadURL, ref} from "firebase/storage"
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";

import firebaseConfig from "./firebaseConfig.js";

import {initializeAppCheck, ReCaptchaV3Provider} from "firebase/app-check";

const app = initializeApp(firebaseConfig);

export const auth = getAuth();
let user = null;


const signIn = () => {
    if (user) return;
    return new Promise((resolve, reject) => {
        signInWithEmailAndPassword(auth, "stockseyeswebsite@stockseyes.com", "433c28b4-0978-488b-a7ae-7c868f36504e")
            .then((userCredential) => {
                // Signed in
                user = userCredential.user;
                resolve(user)
                // ...
            })
            .catch((error) => {
                // const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorMessage);
                reject(errorMessage);
            });
    })

}

signIn();

// Initialize Analytics and get a reference to the service
getAnalytics(app);

// window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

// add app check
initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6Le38Q0kAAAAAOxfmL2scaJiJYTXVUZcYtPZmYCH'),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
});

const db = getFirestore(app);

export const getLeaderboardData = (timeFrame, country, exchange, group, pattern, itemLimit, callback) => {
    console.log(timeFrame, country, exchange, group, pattern, itemLimit)
    const q = query(collection(db, "marketData"), where("metricName", "==", pattern), where("timeFrame", "==", timeFrame), where("tags", "array-contains", group), orderBy("score", "desc"), limit(itemLimit));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const newData = [];
        querySnapshot.forEach((doc) => {
            newData.push(doc.data());
        });
        callback(newData);
        // console.log("New Data: ", JSON.stringify(newData));
    });

    return unsubscribe;
}

export const getCandleStickData = async (stockSymbol, timeFrame, periods) => {
    await signIn();
    const q = query(collection(db, `candles/${stockSymbol}/${timeFrame}`), orderBy("endTime", "desc"), limit(periods));
    const querySnapshot = await getDocs(q);
    const data = [];
    querySnapshot.forEach((doc) => {
        data.push({...doc.data(), id: doc.id});
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
    });
    return data;
}


export const getAllDataOfCollectionPath = async (collectionPath) => {
    await signIn();
    const q = query(collection(db, collectionPath));
    const querySnapshot = await getDocs(q);
    const data = [];
    querySnapshot.forEach((doc) => {
        data.push({...doc.data(), id: doc.id})
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
    });
    return data;
}

export const getAllEnabledOptions = async (collectionPath) => {
    await signIn();
    const q = query(collection(db, collectionPath), where("enabled", "==", true));
    const querySnapshot = await getDocs(q);
    const data = [];
    querySnapshot.forEach((doc) => {
        data.push({...doc.data(), id: doc.id})
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
    });
    return data;
}

export const getDocument = async (documentPath) => {
    await signIn();
    console.log(documentPath)
    const docRef = doc(db, documentPath);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        return docSnap.data();
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document! " + documentPath);
        throw new Error("Document not found for path : " + documentPath)
    }
}


// Saves a new message containing an image in Firebase.
// This first saves the image in Firebase storage.
export const saveVideoToCloud = async (file) => {
    try {
        await signIn();
        console.log("Save video called")

        // 2 - Upload the image to Cloud Storage.
        const filePath = `${"yt"}/${file.name}`;
        const newImageRef = ref(getStorage(), filePath);
        await uploadBytesResumable(newImageRef, file);

        // 3 - Generate a public URL for the file.
        const publicImageUrl = await getDownloadURL(newImageRef);

        return publicImageUrl
    } catch (error) {
        console.error('There was an error uploading a file to Cloud Storage:', error);
    }
}

export const addDocToFirebase = async (collectionPath, data) => {
    await signIn();
    const docRef = await addDoc(collection(db, collectionPath), data);
    return docRef;
}

export const getAllTradingSymbolsForGroup = async (groupId) => {
    await signIn();
    if (groupId === "all") {
        return await getAllDataOfCollectionPath("candles");
    }
    let q = query(collection(db, `candles`), where("tags", "array-contains", groupId));
    const querySnapshot = await getDocs(q);
    const data = [];
    querySnapshot.forEach((doc) => {
        data.push({...doc.data(), id: doc.id})
    });
    return data;
}

export const saveMessage = async (path, data, callback = null) => {
    await signIn();
    try {
        // add timestamp to data
        data["timestamp"] = serverTimestamp();
        await addDoc(collection(db, path), data);
        if (callback) callback();
    } catch (error) {
        console.error('Error writing article to Database', error);
        throw new Error(error);
    }
}

export const getUserId = () => {
    return "Stock Tak";
}
