import React, {useEffect} from 'react';
import '../css/Information.css';
import Information from "../components/Information";
import {Link} from "react-router-dom";

function NotFound() {

    // set document title
    useEffect(() => {
        document.title = "404 Not Found";
    }, []);

    return (
        <Information title={"404!"}>
            <h1 style={{textAlign:"center"}}>You seem Lost! No Worries! Let's take you back <Link to={"/"}><b>HOME</b></Link></h1>
        </Information>
    );
}

export default NotFound;
