/*
* This function uses the replace method to find all capital letters in the input string and adds a space before them.
* The first replace method uses the regular expression /([A-Z])/g to match all capital letters in the string.
* The g flag is used to perform a global search, so all capital letters in the string will be matched and replaced.
* The second replace method is used to capitalize the first letter of the transformed string.
* The function takes a single argument which is the camelCase string and returns the transformed string with spaces and capital letter.
* */
export const capitalizeAndInsertSpaces = (string) => {
    return string.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
}

const url = require('url');

function extractLastPathParam(urlString) {
    const parsedUrl = url.parse(urlString, true);
    const pathSegments = parsedUrl.pathname.split('/').filter(segment => segment !== '');

    if (pathSegments.length > 0) {
        const lastSegment = pathSegments[pathSegments.length - 1];
        return lastSegment;
    }

    return null;
}

function extractVQueryParam(urlString) {
    const parsedUrl = url.parse(urlString, true);
    const vQueryParam = parsedUrl.query.v;
    return vQueryParam || null;
}

export const getVideoId = (urlString) => {
    if(urlString.includes("/shorts/")) {
        return extractLastPathParam(urlString);
    } else {
        return extractVQueryParam(urlString);
    }
}
