import React, {useEffect} from 'react';
import '../css/Information.css';
import Information from "../components/Information";
import UploadVideo from "../components/UploadVideo";

function ShareInsight() {

    // set document title
    useEffect(() => {
        document.title = "Share your Insight";
    }, []);

    return (
        <Information title={"Share Your Insights"}>
            <UploadVideo/>
            <h1>Welcome to "Share insights with StocksEyes" </h1>
            <p>At Stockseyes.com, we provide a platform for individuals to share their stock insights with a community
                of like-minded individuals. Your contributions will be uploaded to our Stockseyes YouTube channel, where
                you can receive feedback, help others, and continue to learn about the stock market. Join us today and
                take the first step in expanding your knowledge and growing your investment portfolio.</p>

            <p>You can record and upload videos that use the metrics and data available on our website to explain
                different aspects of the stock market. Additionally, you can also share your own generated insights and
                analysis.</p>
        </Information>
    );
}

export default ShareInsight;
