import '../css/Footer.css';
import {Link} from "react-router-dom";
import React from "react";

const Footer = () => {
    return (
        <div>
            <footer className="footer">
                {/* Your footer content goes here */}
                <Link to="/disclaimer" style={{color: "white"}}><span style={{marginRight: '20px'}}>Disclaimer</span></Link>
                <Link to="/privacyPolicy" style={{color: "white"}}><span style={{marginRight: '20px'}}>Privacy Policy</span></Link>
                <Link to="/termsAndConditions" style={{color: "white"}}><span style={{marginRight: '20px'}}>Terms & Conditions</span></Link>
            </footer>
            <div style={{marginTop:"100px"}}><p>.</p></div>
        </div>
    );
}
export default Footer;

