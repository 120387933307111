import ArticleCard from "./ArticleCard";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

export default function Articles({articles, columnsCountBreakPoints}) {
    return (
        <>
            <div style={{justifyContent:"center", margin:"auto", width:"90%"}}>
                <ResponsiveMasonry
                    columnsCountBreakPoints={columnsCountBreakPoints}
                >
                    <Masonry gutter={"10px"}>
                        {articles.map(article => <ArticleCard key={article.id} article={article}/>)}
                    </Masonry>
                </ResponsiveMasonry>
            </div>
        </>
    );
}
