import React, {useEffect, useState} from "react";
import "../css/AboutLeaderBoardMetric.css";
import {getDocument} from "../external/firebase";

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className="text">
            {isReadMore ? text.slice(0, 380) : text}
            <span onClick={toggleReadMore} className="read-or-hide">
		{isReadMore ? "...read more" : " show less"}
	</span>
        </p>
    );
};

const AboutLeaderBoardMetric = ({pattern}) => {
    const [text,setText] = useState("");

    useEffect(() => {
       const fetchAbout = async () => {
           const doc = await getDocument(`products/leaderboard/types/${pattern}`);
           setText(doc.about);
       }
       fetchAbout()
    }, [pattern])

    return (
        <div className="container">
            <h2>
                <ReadMore>
                    {text}
                </ReadMore>
            </h2>
        </div>
    );
};

export default AboutLeaderBoardMetric;
