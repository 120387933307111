import React, {useEffect} from 'react';
import '../css/Information.css';
import OptionView from "../components/OptionView";

function LeaderBoardOptions() {
    // set document title
    useEffect(() => {
        document.title = "Leaderboard Selection";
    }, []);

    return (
        <div>
            {/*<Information>*/}
            {/*    <h1 style={{textAlign: "center", marginTop: "20px"}}>Please Choose the Metric</h1>*/}
            {/*    <h2 style={{textAlign: "center", marginTop: "20px", marginBottom: "10px"}}>Single Candle Stick*/}
            {/*        patterns</h2>*/}
            {/*    <p>Single candlestick patterns are an important aspect of technical analysis in the stock market. They*/}
            {/*        provide a visual representation of the price action over a single day or period and are used to*/}
            {/*        identify market trends and make informed trading decisions. The most common single candlestick*/}
            {/*        patterns include the Hammer, Shooting Star, and Doji and they provide key technical signals for*/}
            {/*        potential market reversals, bullish or bearish momentum, and trend exhaustion. By utilizing these*/}
            {/*        patterns, traders can gain valuable insights into market trends and make more informed trading*/}
            {/*        decisions. <b>Please choose any one from below to see how stocks are performing on these patterns.</b></p>*/}
                <OptionView title="Please choose the metric: Candle Stick Patterns" collectionPath={`products/leaderboard/types`} linkSuffix={"/INDIA/NSE"}/>
            {/*    <div>*/}
            {/*        <h2 style={{marginTop: "40px"}}>About Leaderboards</h2>*/}
            {/*        <p>Get real-time insights into the market's top performers with our dynamic*/}
            {/*            leaderboards. Our leaderboards rank stocks, currencies, and other assets based on various*/}
            {/*            metrics*/}
            {/*            such as performance and volatility, refreshed every minute or so for the most up-to-date view of*/}
            {/*            the*/}
            {/*            market. Stay ahead of the game with a clear understanding of which stocks are at the top of*/}
            {/*            their*/}
            {/*            game and make informed investment decisions with ease. Unlock the full potential of your stock*/}
            {/*            market analysis with our live leaderboards!</p>*/}
            {/*    </div>*/}
            {/*</Information>*/}
        </div>
    );
}

export default LeaderBoardOptions;
