import React, { useState, useEffect } from 'react';

function Typewriter({ lines }) {
    const [currentLineIndex, setCurrentLineIndex] = useState(0);
    const [currentLineText, setCurrentLineText] = useState('');
    const [isWriting, setIsWriting] = useState(true);

    useEffect(() => {
        if (currentLineIndex >= lines.length) {
            // Stop writing when all lines are displayed
            setIsWriting(false);
            return;
        }

        const currentLine = lines[currentLineIndex];

        // Write each line letter by letter
        let currentIndex = 0;
        const interval = setInterval(() => {
            if (currentIndex < currentLine.length) {
                setCurrentLineText(currentLine.slice(0,currentIndex+1));
                currentIndex++;
            } else {
                // Move to the next line after a short delay
                setTimeout(() => {
                    setCurrentLineIndex((prevIndex) => (prevIndex + 1)%lines.length);
                    setCurrentLineText(''); // Clear the text for the next line
                }, 1000); // Delay before moving to the next line (in milliseconds)
                clearInterval(interval);
            }
        }, 80); // Interval for letter-by-letter typing (in milliseconds)

        return () => clearInterval(interval);
    }, [currentLineIndex, lines]);

    return (
        <h1 style={{ color: 'white', textAlign:"center", height:"30px"}}>
            {isWriting ? currentLineText : currentLineIndex < lines.length ? lines[currentLineIndex] : ''}
        </h1>
    );
}

export default Typewriter;
