import React, {useEffect, useState} from 'react';
import '../css/Information.css';
import '../css/Options.css';
import {Card, Col, Row, Spin} from "antd";
import {Link} from "react-router-dom";
import {getAllEnabledOptions} from "../external/firebase";

const {Meta} = Card;

function Options(props) {

    const [options, setOptions] = useState([]);
    const [spinning, setSpinning] = useState(true);

    useEffect(() => {
        // fetch from firestore
        const fetchOptions = async () => {
            let data = await getAllEnabledOptions(props.collectionPath);
            data.sort((a, b) => a.priority - b.priority)
            setOptions(data)
            setSpinning(false);
        }
        fetchOptions();
    }, [props.collectionPath])

    return (
        <div>
            <Row gutter={[16, 16]} justify="center">
                <Spin size="large" spinning={spinning}/>
                {options.map(option => {
                    return <Col xs={24} sm={12} md={8} lg={8} xl={8} style={{textAlign: 'center'}} key={option.title}>
                        <Link
                            to={option.link + ((option.link !== "/customRequirements" && props.linkSuffix) ? props.linkSuffix : "")}>
                            <Card
                                hoverable={true}
                                cover={
                                    <img
                                        height="250px"
                                        alt={option.title}
                                        src={option.coverImage ? option.coverImage : "https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"}
                                    />
                                }
                            >
                                <Meta
                                    title={option.title}
                                    description={<div style={{minHeight: "80px"}}>{option.description ? option.description : "Just enter and see what's inside"}</div>}
                                />
                            </Card>
                        </Link>
                    </Col>
                })}
            </Row>
        </div>
    );
}

export default Options;
