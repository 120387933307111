export const keysToEliminateFromHeader = [
    "nifty50",
    "key",
    "timeFrame",
    "metric",
    "country",
    "tags",
    "type",
    "exchange",
    "metricName"
]

export const keysToInclude = [
    "tradingSymbol",
    "name",
    "score",
    "updatedAt"
]

export const orderOfColumnsInHeader = {
    tradingSymbol: 0,
    score: 1,
    name: 2,
    percentageChange: 3,
    updatedAt: 4
}

export const specificMetricProperties = {
    percentageChange: {
        multiplier: 100,
        tableHeaderClass: "green-header",
        dataColor: "green"
    },
    bullishPercentageChange: {
        multiplier: 100,
        tableHeaderClass: "green-header",
        dataColor: "green"
    },
    bullishMarubuzo: {
        multiplier: 100,
        tableHeaderClass: "green-header",
        dataColor: "green"
    },
    bearishMarubuzo: {
        multiplier: 100,
        tableHeaderClass: "red-header",
        dataColor: "red"
    },
    spinningTop: {
        multiplier: 100,
        tableHeaderClass: "blue-header",
        dataColor: "blue"
    },
    doji: {
        multiplier: 100,
        tableHeaderClass: "blue-header",
        dataColor: "blue"
    },
    paperUmbrella: {
        multiplier: 100,
        tableHeaderClass: "blue-header",
        dataColor: "blue"
    },
    shootingStar: {
        multiplier: 100,
        tableHeaderClass: "red-header",
        dataColor: "red"
    },
    bearishPercentageChange: {
        multiplier: 100,
        tableHeaderClass: "red-header",
        dataColor: "red"
    },


    // multiple candlestick patterns
    bullishEngulfing:{
        multiplier: 100,
        tableHeaderClass: "green-header",
        dataColor: "green"
    },
    bearishEngulfing:{
        multiplier: 100,
        tableHeaderClass: "red-header",
        dataColor: "red"
    },
    bullishEngulfingWithDoji:{
        multiplier: 100,
        tableHeaderClass: "green-header",
        dataColor: "green"
    },
    bearishEngulfingWithDoji:{
        multiplier: 100,
        tableHeaderClass: "red-header",
        dataColor: "red"
    },
    piercing:{
        multiplier: 100,
        tableHeaderClass: "green-header",
        dataColor: "green"
    },
    darkCloudCover:{
        multiplier: 100,
        tableHeaderClass: "red-header",
        dataColor: "red"
    },
    bullishHarami:{
        multiplier: 100,
        tableHeaderClass: "green-header",
        dataColor: "green"
    },
    bearishHarami:{
        multiplier: 100,
        tableHeaderClass: "red-header",
        dataColor: "red"
    },
    morningStar:{
        multiplier: 100,
        tableHeaderClass: "green-header",
        dataColor: "green"
    },
    eveningStar:{
        multiplier: 100,
        tableHeaderClass: "red-header",
        dataColor: "red"
    },
    isUptrend: {
        multiplier: 100,
        tableHeaderClass: "green-header",
        dataColor: "green"
    },
    isDowntrend: {
        multiplier: 100,
        tableHeaderClass: "red-header",
        dataColor: "red"
    }
}

export const columnProperties = {
    tradingSymbol: {
        fontWeight: "bold"
    }
}

export const contactDetails = {
    phone: "+91 8618101211",
    email: "stockseyeslive@gmail.com"
}

export const blogStorage = {
    path: "blogs"
}
