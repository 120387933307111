import logo from '../assets/logo.png';

import '../css/Header.css';

function LeaderboardHeader(props) {
    return (
            <div className='header'>
                <img src={logo} alt="logo" className='logo'/>
                <span className='header-text'> {props.headerText}</span>
            </div>

    );
}

export default LeaderboardHeader;
