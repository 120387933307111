import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, message, Upload} from 'antd';
import {useState} from 'react';
import {saveVideoToCloud} from "../external/firebase";
import TextArea from "antd/es/input/TextArea";

const beforeUpload = (file) => {
    // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    // if (!isJpgOrPng) {
    //     message.error('You can only upload JPG/PNG file!');
    // }
    const isLt2M = file.size / 1024 / 1024 < 100;
    if (!isLt2M) {
        message.error('Video must smaller than 100MB!');
    }
    return false;
};
const UploadVideo = () => {
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [videoUrl, setVideoUrl] = useState();
    const [videoDescription, setVideoDescription] = useState("");

    const handleChange = async (info) => {
        console.log(info)
        setLoading(true);
        const videoUrl = await saveVideoToCloud(info.file);
        setVideoUrl(videoUrl)
        setLoading(false);
        // if (info.file.status === 'uploading') {
        //     setLoading(true);
        //     return;
        // }
        // if (info.file.status === 'done') {
        //     setLoading(false);
        //     // Get this url from response in real world.
        //     // getBase64(info.file.originFileObj, (url) => {
        //     //     setLoading(false);
        //     //     setImageUrl(url);
        //     // });
        // }
    };
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const handlePublish = async () => {
        if (!videoUrl) {
            message.error("Please upload the video first");
            return;
        }

        if (!videoDescription || videoDescription === "") {
            message.error("Please add video description");
            return;
        }

        setButtonLoading(true)
        // main logic to save after youtube approves
        await new Promise(resolve => setTimeout(resolve, 3000));

        setButtonLoading(false);
        setVideoUrl(undefined);
        setVideoDescription("");
        message.info("Your video is being reviewed , it will be published as soon as it passes the review", 10);
    }

    const handleRemove = async () => {
        message.info("We are working on it. Your videos will be removed in 24 hrs.", 8)
    }

    return (
        <div>
            <div style={{display: "flex", justifyContent: "center"}}>

                {!videoUrl ? <div style={{width: "100px"}}>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {videoUrl ? (
                                <img
                                    src={videoUrl}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </div>
                    :
                    <video controls src={videoUrl} preload="auto"
                           style={{width: "100%", height: "100%", marginBottom: "40px"}}></video>}

            </div>

            <TextArea
                style={{marginBottom: "50px"}}
                rows={4} placeholder="please describe your video in few words"
                value={videoDescription}
                onChange={(e) => setVideoDescription(e.target.value)}
                maxLength={500}
            />

            <div style={{display: "flex", justifyContent: "center"}}>
                <Button loading={buttonLoading} type="primary" danger onClick={handlePublish} size={"large"}>
                    Publish to StocksEyes Youtube Channel
                </Button>
            </div>
            <br/>
            <div style={{display: "flex", justifyContent: "center"}}>
                <a
                    href="https://www.youtube.com/@stockeyesBB/about"
                    target="_blank"
                    rel="noreferrer"
                >
                    StocksEyes Youtube channel
                </a>
            </div>
            <br/>

            <div style={{display: "flex", justifyContent: "center"}}>
                <Button type="primary" danger onClick={handleRemove}>
                    Remove my videos from StocksEyes Youtube Channel
                </Button>
            </div>
            <br/>
            <br/>

        </div>
    );
};
export default UploadVideo;