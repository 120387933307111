import {Card} from "antd";
import {Link} from "react-router-dom";

const {Meta} = Card;
export default function ArticleCard({article}) {
    return (
        <Link to={`/blog/${article.id}`}>
            <Card
                style={{width: "100%"}}
                cover={
                    <img
                        alt={article.articleName}
                        src={article.coverImage}
                    />

                }
            >
                <Meta
                    title={article.articleName}
                    description={article.subHeading}
                />
            </Card>
        </Link>
    )
}
