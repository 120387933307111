import React, {useEffect} from 'react';
import '../css/Information.css';
import Information from "../components/Information";
import {contactDetails} from "../config/stockEyesConfig";

function PrivacyPolicy() {

    // set document title
    useEffect(() => {
        document.title = "Privacy Policy";
    }, []);

    return (
        <Information title={"Privacy Policy"}>
            <p>Our Privacy Policy explains how we collect, use, and protect the personal information you provide to us when you visit our website. Please read this policy carefully to understand our practices regarding your personal information and how we will treat it.</p>
            <p>Information we collect</p>
            <p>We may collect personal information from you such as your name, email address, and other contact information when you register for an account, subscribe to our newsletter, or participate in a survey or other promotional activity on our website.</p>
            <p>We may also collect non-personal information, such as your IP address, browser type, and other technical information when you visit our website. We use this information to analyze and improve our website, as well as to personalize your experience when you visit it.</p>
            <p>Use of your information</p>
            <p>We use the information we collect from you to provide you with the products and services you request, as well as to improve our website and the services we offer. We may use your information to send you promotional emails and other communications about our products and services, or to contact you for feedback or market research purposes.</p>
            <p>We may also use your information to personalize your experience on our website, such as by providing you with content and advertising that is more relevant to your interests.</p>
            <p>Sharing of your information</p>
            <p>We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except in the following cases:</p>
            <ul>
                <li>We may share your information with our affiliates and partners, who may use it to send you promotional materials or to contact you for market research purposes.</li>
                <li>We may share your information with third-party service providers, such as website hosting companies, analytics providers, and other contractors who assist us in operating our website and providing our services.</li>
                <li>We may disclose your information if we are required to do so by law or if we believe that such action is necessary to comply with legal process, enforce our website policies, or protect the rights, property, or safety of our company, our customers, or others.</li>
            </ul>
            <p>Security of your information</p>
            <p>We take the security of your personal information very seriously and have implemented appropriate technical and organizational measures to protect it from unauthorized access, use, disclosure, alteration or destruction.</p>
            <p>We use Secure Socket Layer (SSL) technology to encrypt the information you provide to us when you register for an account or make a purchase on our website. We also use firewalls and intrusion detection systems to protect our servers from unauthorized access.</p>
            <p>However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while we strive to protect your personal information, we cannot guarantee its absolute security.</p>
            <p>Changes to our privacy policy</p>
            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or to comply with legal&nbsp;requirements. We will notify you of any material changes by posting the new policy on our website and updating the "Last Updated" date at the top of this policy. We encourage you to periodically review this policy to stay informed about how we are protecting the personal information we collect. Your continued use of our website following the posting of changes to this policy will constitute your acceptance of those changes.</p>
            <p>Contact Us</p>
            <p>If you have any questions or concerns about our Privacy Policy or the information we collect, please contact us by email at <a href={`mailto:${contactDetails.email}`} target="_new">{contactDetails.email}</a> or by phone at {contactDetails.phone}.</p>
            <p>This policy is effective as of 1 Jan 2023.</p>
        </Information>
    );
}

export default PrivacyPolicy;
