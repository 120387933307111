import React from 'react';
import '../css/Information.css';
import OptionView from "../components/OptionView";
import WebsiteDescription from "../components/WebsiteDescription";

function LeaderBoardOptions() {
    return (
        <OptionView title="Choose the Country" collectionPath="countries">
            <WebsiteDescription/>
        </OptionView>
    );
}

export default LeaderBoardOptions;
