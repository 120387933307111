import React from 'react';
import '../css/Information.css';
import OptionView from "../components/OptionView";
import {useParams} from "react-router-dom";
import WebsiteDescription from "../components/WebsiteDescription";

function ExchangeOptions() {
    const params = useParams();

    return (
        <div>
            <OptionView title="Choose the Exchange" collectionPath={`countries/${params.country}/exchanges`}>
                <WebsiteDescription/>
            </OptionView>
        </div>
    );
}

export default ExchangeOptions;
