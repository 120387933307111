import React from 'react';
import '../css/Information.css';
import '../css/Home.css';
import Options from "../components/Options";
import Typewriter from "../components/TypeWriter";
import BaseView from "../components/BaseView";

function Home() {
    const lines = [
        "Unleash the Power of Data: Identify Top Performers Matching Any Strategy!",
        "Get live Alerts",
        "Unlock Your Winning Potential: Discover Top-Performing Stocks with Ease!",
        "Your Winning Edge Awaits: Find Top Stocks that Meet Your Criteria!",
        "Empowering Investors with Market Insights.",
        "Real-Time Leaderboards for Winning Trades.",
        "Stay Ahead with Live Market Rankings.",
        "Your Gateway to Informed Decisions.",
        "Simplify Investing with Expert Analysis.",
        "Thorough Research for Smarter Trades.",
        "Discover Winning Stocks with Us.",
        "Unleash the Power of Data-Driven Investing.",
        "Excel in the Stock Market with Our Tools.",
        "Join the Community of Savvy Investors."
    ];
    return (
        <BaseView>
            <div className="top-margin"/>
            <div>
                <Typewriter lines={lines}/>
            </div>
            <div className="margin-before-options"/>
            <div className="option-margin">
                <Options collectionPath="products"/>
            </div>
        </BaseView>
    );
}

export default Home;
