import React, {useEffect} from 'react';
import '../css/Information.css';
import OptionView from "../components/OptionView";

function ExcelOptions() {

    // set document title
    useEffect(() => {
        document.title = "Stocks Excel Download";
    }, []);


    return (
        <OptionView title="Please Select the Type of Data" collectionPath={`products/excelDownload/types`} linkSuffix="/INDIA/NSE">
            <div>
                <h2 style={{marginTop: "40px"}}>About Excel/CSV Downloads</h2>
                <p>Take control of your stock market analysis with the live data available on our Excel download page!
                    Download real-time stock information, including candles and analysis, and carry out your own
                    analysis in the comfort of Excel or CSV format. Stay ahead of the market with the most up-to-date
                    insights, and make informed investment decisions with the power of live data at your fingertips.</p>
            </div>
        </OptionView>
    );
}

export default ExcelOptions;
