import '../App.css';
import {useParams} from "react-router-dom";

import '../css/Header.css';
import LeaderBoardTable from "../components/LeaderBoardTable";
import LeaderboardHeader from "../components/LeaderBoardHeader";
import TopHeader from "../components/TopHeader";
import {capitalizeAndInsertSpaces} from "../utils/common";
import Footer from "../components/Footer";
import AboutLeaderBoardMetric from "../components/AboutLeaderBoardMetric";
import MovingText from "../components/MovingText";

function Leaderboard() {
    const params = useParams();
    const headerText = capitalizeAndInsertSpaces(params.productId) + " LEAGUE"
    return (<div style={{paddingBottom: "100px"}}>
            <TopHeader/>
            <MovingText text={
                <p>LIVE &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp; LIVE &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp; LIVE &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp; LIVE &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp; LIVE &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp; LIVE &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp; LIVE &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp; LIVE &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp; LIVE &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp; LIVE &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp; LIVE &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp; LIVE &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp; LIVE &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp; LIVE &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp; LIVE &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp; LIVE &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp; LIVE  &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp; LIVE  &nbsp; &nbsp; &nbsp; &bull; &nbsp; &nbsp; &nbsp; LIVE</p>}/>
            <LeaderboardHeader headerText={headerText}/>
            <AboutLeaderBoardMetric pattern={params.productId}/>
            <LeaderBoardTable headerText={headerText} country={params.country} exchange={params.exchange}
                              group={params.group} pattern={params.productId}/>
            {/*<p>{JSON.stringify(params)}</p>*/}
            <Footer/>
        </div>
    );
}

export default Leaderboard;
