import React from 'react';
import {contactDetails} from "../config/stockEyesConfig";

const WebsiteDescription = () => (
    <div style={{
        marginTop: "20px"
    }}>
        <br/>
        <h1>Welcome to StocksEyes</h1>
        <p>We provide valuable insights and analysis on the stock market, as well as live leaderboards for
            stocks, currencies, and other tradables.</p>
        <p>Our goal is to empower investors and traders with the information they need to make informed
            decisions and stay ahead of the market trends. We understand that keeping track of multiple
            stocks and different metrics can be overwhelming and time-consuming, which is why we created our
            website to solve this problem.</p>
        <p>Our stock insights are the result of thorough research and analysis, and are designed to give you
            a deeper understanding of the market and individual stocks. Our live leaderboards, which are
            updated in real-time, rank stocks, currencies, and other tradable assets based on various
            metrics such as performance and volatility. The leaderboards are refreshed every minute or so,
            depending on the time frame chosen, giving you a clear and up-to-date view of the market all at
            once, without the mental load of doing it yourself.</p>
        <p>In addition to our leaderboards, we also provide excel sheet downloads for various strategies and
            data related to stocks and tradables. These downloads are designed to help you make the most of
            your investments and stay up-to-date on the latest market trends.</p>
        <p>At Stockseyes.com, we also provide a platform for individuals to share their stock insights with a community
            of like-minded individuals. Your contributions will be uploaded to our Stockseyes YouTube channel, where
            you can receive feedback, help others, and continue to learn about the stock market. Join us today and
            take the first step in expanding your knowledge and growing your investment portfolio.</p>
        <p>In addition to our standard leaderboards and excels, we also provide the flexibility of custom
            leaderboards to suit your specific needs. If you're interested in a custom leaderboard or daily
            excel downloads, please don't hesitate to reach out to us by emailing at
            {contactDetails.email} or by calling us on {contactDetails.phone}.</p>
        <p>Whether you're a seasoned professional or just starting out, our website has something for you.
            Thank you for choosing our website as your go-to source for market insights and analysis. We
            look forward to helping you make the most of your investment decisions.</p>
    </div>
);

export default WebsiteDescription;