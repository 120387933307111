import React, {useEffect, useState} from 'react';
import '../css/Information.css';
import Information from "../components/Information";
import {useParams} from "react-router-dom";
import {Button, Select, Table, Tag} from "antd";
import {getAllTradingSymbolsForGroup} from "../external/firebase";
import {downloadFile} from "../commons/excelDownload";

function Group() {
    const params = useParams();
    const [groupId, setGroupId] = useState(params.groupId);
    const [tableData, setTableData] = useState([]);
    const [downloadGroupLoading, setDownloadGroupLoading] = useState(false);
    const columns = [{
        title: "Trading Symbol",
        dataIndex: "tradingSymbol",
        key: "tradingSymbol",
        align: 'center',
        render: (text, record, index) => (
            <span>{text}</span>
        )
    }, {
        title: "Name",
        dataIndex: "name",
        key: "tradingSymbol",
        align: 'center',
        render: (text, record, index) => (
            <span>{text}</span>
        )
    }, {
        title: 'Part of Groups',
        key: 'tags',
        dataIndex: 'tags',
        align: 'center',
        render: (_, {tags}) => (
            <>
                {tags.map((tag) => {
                    let color = tag.length > 5 ? 'geekblue' : 'green';
                    if (tag === 'loser') {
                        color = 'volcano';
                    }
                    return (
                        <Tag color={color} key={tag}>
                            {tag.toUpperCase()}
                        </Tag>
                    );
                })}
            </>
        ),
    }
    ]

    const onChangeStockSymbol = (value) => {
        console.log("Group Id" + value)
        setGroupId(value);
    }

    const getTheStocksOfGroup = async (groupId) => {
        let data = await getAllTradingSymbolsForGroup(groupId);
        data.forEach(row => {
            row.key = row.tradingSymbol
        })
        setTableData(data);
    }

    useEffect(() => {
        // can be optimized to fetch only the first 10 and paginate
        getTheStocksOfGroup(groupId);
    }, [groupId])

    const handleTableChange = (pagination) => {
        console.log(pagination)
    }

    const downloadGroup = () => {
        setDownloadGroupLoading(true);

        // Headers for each column
        let headers = ['Trading Symbol, Name, Part Of Groups']

        // Convert users data to a csv
        let candleCsv = tableData.reduce((csvData, candle) => {
            const {tradingSymbol, name, tags} = candle
            csvData.push([tradingSymbol, name, tags.join(" and ")].join(','))
            return csvData
        }, [])

        downloadFile({
            data: [...headers, ...candleCsv].join('\n'),
            fileName: `${groupId}_stocks.csv`,
            fileType: 'text/csv',
        })

        setDownloadGroupLoading(false);
    }

    return (
        <Information title={"NSE Groups"}>
            <div style={{display: 'flex', justifyContent: 'center', marginBottom: "20px"}}>
                <Select
                    onChange={onChangeStockSymbol}
                    showSearch
                    style={{
                        width: 500,
                    }}
                    size="large"
                    placeholder="Search Group"
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                    // filterSort={(optionA, optionB) =>
                    //     (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    // }
                    options={[
                        {
                            value: "nifty50",
                            label: "NIFTY 50"
                        },
                        {
                            value: "niftyNext50",
                            label: "NIFTY NEXT 50"
                        },
                        {
                            value: "niftyMidCap50",
                            label: "NIFTY MID CAP 50"
                        },
                        {
                            value: "bankNifty",
                            label: "NIFTY BANK"
                        },
                        {
                            value: "niftySectorIndices",
                            label: "NIFTY SECTOR INDICES"
                        },
                        {
                            value: "all",
                            label: "See All Trading Symbols Supported by StocksEyes"
                        }
                    ]}
                />
            </div>

            <div style={{display: "flex", justifyContent: "flex-end", marginBottom: "20px"}}>
                <Button type="primary" onClick={downloadGroup} loading={downloadGroupLoading}>Download as csv</Button>
            </div>

            <Table columns={columns} dataSource={tableData}
                   loading={!tableData || tableData.length === 0}
                   onChange={handleTableChange}
            />

        </Information>
    );
}

export default Group;
