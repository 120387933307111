import React, {useEffect} from 'react';
import '../css/Information.css';
import Information from "../components/Information";
import {Link} from "react-router-dom";

function Unsubscribe() {

    // set document title
    useEffect(() => {
        document.title = "Unsubscribe";
    }, []);

    return (
        <Information title={"Feeling Sad to see you go"}>
            <h1 style={{textAlign:"center"}}>Successfully Unsubscribed. Thank you !! <Link to={"/"}><b>HOME</b></Link></h1>
        </Information>
    );
}

export default Unsubscribe;
