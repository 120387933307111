import React, {useEffect, useState} from 'react';
import '../css/Information.css';
import Information from "../components/Information";
import TextArea from "antd/es/input/TextArea";
import {Button, Input, Tooltip, message} from "antd";
import {InfoCircleOutlined, MailOutlined} from "@ant-design/icons";
import {addDocToFirebase} from "../external/firebase";

function CustomRequirements() {
    const [customRequirement, setCustomRequirement] = useState("");
    const [email, setEmail] = useState("");

    const [buttonLoading, setButtonLoading] = useState(false);

    // set document title
    useEffect(() => {
        document.title = "Custom Requirements";
    }, []);

    const submitCustomRequirements = async () => {
        setButtonLoading(true);

        // verify data
        // check custom requirements
        if (!customRequirement || customRequirement.length === 0) {
            message.error("Please fill custom requirements");
            setButtonLoading(false)
            return;
        }
        // check email

        if (!email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            message.error("Please fill a valid email");
            setButtonLoading(false)
            return;
        }

        // save to firestore
        await addDocToFirebase(`customRequirements`, {
            customRequirement,
            email
        })

        message.info("Your requirement has been successfully submitted, we will get back as soon as possible")
        setCustomRequirement("");
        setEmail("");
        setButtonLoading(false);
    }

    return (
        <Information title={"Custom Requirements"}>
            <h3>Please Post Your Requirement</h3>

            <TextArea
                style={{marginBottom: "10px"}}
                rows={4} placeholder="please describe your requirements"
                value={customRequirement}
                onChange={(e) => setCustomRequirement(e.target.value)}
            />

            <Input
                placeholder="Enter your Email Id , we will get back as soon as possible"
                prefix={<MailOutlined/>}
                suffix={
                    <Tooltip title="We will revert back on this email as soon as possible!">
                        <InfoCircleOutlined style={{color: 'rgba(0,0,0,.45)'}}/>
                    </Tooltip>
                }
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />

            <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                <Button loading={buttonLoading} type="primary" onClick={submitCustomRequirements} size={"large"}>
                    Submit my requirements
                </Button>
            </div>

            <br/>
            <br/>
            <br/>
            <p>Unlock the full potential of your stock market analysis with our custom requirements page! Here, you
                can&nbsp;post&nbsp;your requirements for custom leaderboards to suit your specific needs by defining the
                metrics on which stocks are ranked. Additionally, you can request custom stock data downloads in your
                preferred format, including Excel. Maximize your investment strategy and stay ahead of the competition
                with the flexibility of our custom requirements page.</p>

        </Information>
    );
}

export default CustomRequirements;
