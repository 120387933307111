import React, {useEffect} from 'react';
import '../css/Information.css';
import Information from "../components/Information";
import {contactDetails} from "../config/stockEyesConfig";

function TermsAndConditions() {

    // set document title
    useEffect(() => {
        document.title = "Terms and Conditions";
    }, []);


    return (
        <Information title={"Terms And Conditions"}>
            <p>Welcome to StocksEyes! By accessing or using our website, you agree to be bound by the following terms and conditions of use. If you do not agree to these terms and conditions, please do not use our website.</p>
            <ol>
                <li>Use of the Website</li>
            </ol>
            <p>You may use our website only for lawful purposes and in accordance with these terms and conditions. You agree not to use the website:</p>
            <ul>
                <li>In any way that violates any applicable federal, state, local, or international law or regulation.</li>
                <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</li>
                <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter," "spam," or any other similar solicitation.</li>
                <li>To impersonate or attempt to impersonate us, our employees, or any other person or entity.</li>
                <li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the website, or which, as determined by us, may harm us or users of the website or expose them to liability.</li>
            </ul>
            <ol start="2">
                <li>Intellectual Property Rights</li>
            </ol>
            <p>The website and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof), are owned by us, our licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
            <ol start="3">
                <li>User Content</li>
            </ol>
            <p>By posting, uploading, inputting, providing, or submitting any information or material on or through the website, you grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sublicensable license to use, reproduce, distribute, prepare derivative works of, display, perform, and otherwise exploit the content in connection with the website and our business.</p>
            <ol start="4">
                <li>Disclaimer of Warranties</li>
            </ol>
            <p>The website is provided on an "as is"&nbsp;and "as available" basis. We make no representations or warranties of any kind, express or implied, as to the operation of the website or the information, content, materials, or products included on the website. You expressly agree that your use of the website is at your sole risk.</p>
            <p>To the full extent permissible by applicable law, we disclaim all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose. We do not warrant that the website, its servers, or any email sent from us are free of viruses or other harmful components. We will not be liable for any damages of any kind arising from the use of the website, including, but not limited to, direct, indirect, incidental, punitive, and consequential damages.</p>
            <ol start="5">
                <li>Limitations of Liability</li>
            </ol>
            <p>In no event shall we, our directors, officers, employees, agents, or assigns be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on our website, even if we or an authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
            <ol start="6">
                <li>Indemnification</li>
            </ol>
            <p>You agree to indemnify, defend, and&nbsp;hold us, our affiliates, officers, agents, and employees harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your breach of these terms and conditions or the documents they incorporate by reference, or your violation of any law or the rights of a third party.</p>
            <ol start="7">
                <li>Severability</li>
            </ol>
            <p>If any provision of these terms and conditions is found to be invalid under any applicable law, such provision shall be deleted without affecting the remaining provisions herein.</p>
            <ol start="8">
                <li>Variation of Terms</li>
            </ol>
            <p>We reserve the right to revise these terms and conditions at any time as it sees fit, and by using this website you are expected to review these terms on a regular basis.</p>
            <ol start="9">
                <li>Assignment</li>
            </ol>
            <p>The Company shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification or consent required. However, .you shall not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>
            <ol start="10">
                <li>Entire Agreement</li>
            </ol>
            <p>These terms and conditions, together with our Privacy Policy, constitute the entire agreement between you and us in relation to your use of this website, and supersede all prior agreements and understandings.</p>
            <ol start="11">
                <li>Our details</li>
            </ol>
            <p>The full name of the company is&nbsp;StocksEyes and it can be contacted by email at <a href={`mailto:${contactDetails.email}`} target="_new">{contactDetails.email}</a> or by phone at {contactDetails.phone}.</p>
        </Information>
    );
}

export default TermsAndConditions;
