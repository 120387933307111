import React from 'react';
import '../css/Information.css';
import Information from "../components/Information";
import Options from "../components/Options";

// Import the CSS file that contains the new classes
import '../css/OptionView.css';
//
// function BaseView(props) {
//     return (
//         <div className="option-view-container">
//             <div className="background-image">
//                 <div className="overlay"></div> {/* Add the overlay element */}
//             </div>
//             <Information>
//                 <h1 style={{ textAlign: "center", marginTop: "20px", color:"white" }}>{props.title}</h1>
//                 <Options collectionPath={props.collectionPath} linkSuffix={props.linkSuffix}/>
//                 {props.children}
//             </Information>
//         </div>
//     );
// }
//
// export default BaseView;


function OptionView(props) {
    const overlayStyle = {
        backgroundColor: 'black', // The color of the overlay
        opacity: 0.6, // You can adjust the opacity as needed
    };

    return (
        <div className="image-overlay-container">

            <div className="background-image"
                 style={{backgroundImage: `url('https://images.pexels.com/photos/6770610/pexels-photo-6770610.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')`}}></div>
            <div className="overlay" style={overlayStyle}></div>
            <div className="content">
                <Information title={props.title}>
                    <div style={{margin: "50px"}}/>
                    {/*<h1 style={{textAlign: "center", marginTop: "20px", color: "white"}}>{props.title}</h1>*/}
                    <Options collectionPath={props.collectionPath} linkSuffix={props.linkSuffix}/>
                    <div style={{margin: "50px"}}/>
                    <div style={{color:"white"}}>
                    {props.children}
                    </div>
                </Information>
            </div>


        </div>
    );
}

export default OptionView;




