import React, {useEffect, useState} from 'react';
import Articles from "../components/Articles";
import {getAllDataOfCollectionPath} from "../external/firebase";
import BaseView from "../components/BaseView";

function Blogs() {

    const [articles, setArticles] = useState([]);

    // set document title
    useEffect(() => {
        document.title = "Blogs";
    }, []);

    // fetch all articles
    useEffect(() => {
        const getAllArticles = async (count) => {

            const populateExtraFields = (data, articleId) => {
                data.timestamp = data.timestamp.toDate().toDateString();
                data.id = articleId;
                data.userId = data.writerId || "5oDLZGgh1nStaB08aMXh8zvamcA2";
                data.writerId = data.userId
                return data;
            }
            let recentArticles = await getAllDataOfCollectionPath("blogs");
            recentArticles = recentArticles.map(article => populateExtraFields(article, article.id))
            setArticles(recentArticles)
        }
        getAllArticles()
    })

    return (
        <BaseView>
            <div style={{marginTop:"50px"}}>
                <Articles articles={articles} columnsCountBreakPoints={{350: 1, 790: 2, 1200: 3}}/>
            </div>
        </BaseView>
    );
}

export default Blogs;
