import {getDocument} from "../external/firebase";
import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import TopHeader from "../components/TopHeader";
import Footer from "../components/Footer";
import "../css/Blog.css";
import {getVideoId} from "../utils/common";

const ArticlePage = () => {

    const params = useParams();

    const [article, setArticle] = useState(null);

    useEffect(() => {
        // Simulating fetching article data
        const fetchArticleData = async () => {
            try {
                const article = await getDocument(`blogs/${params.blogId}`)
                setArticle(article);
            } catch (error) {
                console.error('Error fetching article data:', error);
            }
        };

        fetchArticleData();
    }, [params.blogId]);

    const formattedDate = article
        ? new Intl.DateTimeFormat('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        }).format(new Date(article.date))
        : '';

    if (article)
        return (
            <div>
                <TopHeader/>
                <div className="article-container">
                    <h1 className="article-title">{article.articleName}</h1>

                    <p className="by-line">By <b>{article.writerId}</b></p>

                    <p className="date-time">{formattedDate}</p>

                    <h3 className="sub-heading">"{article.subHeading}"</h3>

                    <p className={"prefer-listening"}>Prefer Listening? You can also listen to audio of this article!</p>
                    <audio controls className="audio-player">
                        <source src={article.audio} type="audio/wav"/>
                        Your browser does not support the audio element.
                    </audio>
                    <br/><br/><br/>
                    <p className={"prefer-listening"}>"Visual Learner? Discover More with Our Engaging Video Presentation – Unveil the Story in Motion!"</p>
                    <div className={"youtube-video-container"}>
                    {article.youtubeVideoLink && (
                        <iframe className={"youtube-video-iframe"}
                                src={`https://www.youtube.com/embed/${getVideoId(article.youtubeVideoLink)}`}
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen>

                        </iframe>
                    )}
                    </div>

                    <div
                        className="article-content"
                        dangerouslySetInnerHTML={{__html: article.description}}
                    />
                </div>
                <Footer/>
            </div>
        );
    else return (<div/>);
};

export default ArticlePage;
