import React, {useEffect} from 'react';
import '../css/Information.css';
import Information from "../components/Information";
import {contactDetails} from "../config/stockEyesConfig";

function Contact() {

    // set document title
    useEffect(() => {
        document.title = "Contact us";
    }, []);

    return (
        <Information title={"Contact us"}>
            <p>Welcome to our contact page! We appreciate your interest in our website and are here to help with any
                questions or concerns you may have.</p>
            <p>You can reach us by:</p>
            <ul>
                <li>Email: You can send us an email at <a href={`mailto:${contactDetails.email}`}
                                                          target="_new">{contactDetails.email}</a> and we will respond
                    as soon as possible.
                </li>
                <li>Phone: You can give us a call at {contactDetails.phone} anytime.</li>
            </ul>
            <p>Thank you for choosing our website. We look forward to hearing from you!</p>
        </Information>
    );
}

export default Contact;
