import React, {useEffect} from 'react';
import '../css/Information.css';
import Information from "../components/Information";

function Disclaimer() {

    // set document title
    useEffect(() => {
        document.title = "Disclaimer";
    }, []);


    return (
        <Information title={"Disclaimer"}>
            <p>The information contained on this website is for general information purposes only. The information is provided by us and while we endeavor to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>
            <p>In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</p>
            <p>Through this website, you are able to link to other websites which are not under the control of us. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>
            <p>Every effort is made to keep the website up and running smoothly. However, us takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</p>
            <p>This website is provided for informational purposes only and should not be considered as investment advice or a recommendation of any security, strategy or investment product. The information provided on this website is not intended for distribution to, or use by, any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation.</p>
            <p>All information provided on this website is impersonal and not tailored to the needs of any person, entity or group of persons.</p>
            <p>Stock prices, indexes, futures, or any other financial instrument that is mentioned on this website can be affected by many factors and can therefore change rapidly. We are not responsible for any inaccuracies or errors in the information provided on this website, or for any financial loss or other damage resulting from the use of this website or the information provided on it.</p>
            <p>Please be aware that any opinions, estimates, or forecasts regarding the performance of a security or a market made by us are based on assumptions, market conditions, and other factors that may change. Past performance is not indicative of future results.</p>
            <p>This website may contain forward-looking statements, which are based on our current expectations, estimates and projections about the industry and markets in which we operate, and our beliefs and assumptions. These statements are not guarantees of future performance or events, and are subject to risks, uncertainties and other factors, some of which are beyond our control and are difficult to predict. Actual results may differ materially from those stated or implied in forward-looking statements due to a variety of factors.</p>
            <p>The information on this website is not intended to be a substitute for professional advice or judgment, and should not be relied upon in that regard. You should seek professional advice, as appropriate, regarding the evaluation of any specific information, opinion, advice, or other content.</p>
            <p>By using this website, you accept the terms of this disclaimer. If you do not agree with the terms of this disclaimer, please do not use this website.</p>
        </Information>
    );
}

export default Disclaimer;
