import React from 'react';
import '../css/Information.css';
import BaseView from "./BaseView";

function Information(props) {
    return (
        <BaseView>
            <div className="about-page" style={{color:"white"}}>
                <h1 style={{margin: "20px", textAlign: "center", fontSize: "40px", color: "white"}}>{props.title}</h1>
                {props.children}
            </div>
        </BaseView>

        // <div>
        //     <TopHeader/>
        //     <div className="about-page">
        //         {/*<div style={{display: 'flex', justifyContent: 'center'}}>*/}
        //         {/*    <img src={logo} alt="stocks-eyes-logo" style={{maxWidth: '100%'}} />*/}
        //         {/*</div>*/}
        //         <h1 style={{margin:"20px", textAlign:"center", fontSize:"40px", color:"white"}}>{props.title}</h1>
        //
        //         {props.children}
        //     </div>
        //     <Footer/>
        // </div>
    );
}

export default Information;
