import React, {useEffect} from 'react';
import '../css/Information.css';
import Information from "../components/Information";
import {Link} from "react-router-dom";

// Deprecated
// Refer API.js

function API() {

    // set document title
    useEffect(() => {
        document.title = "StocksEyes API";
    }, []);

    return (
        <Information title={"StocksEyes API"}>

            <p>
                StocksEyes provides a comprehensive API that provides real-time candlestick data (OHLCV - open, high,
                low,
                close, volume) for stocks and other securities. With stocksEyes, you have access to live prices and last
                traded prices, as well as a wealth of historical data. This powerful tool is ideal for traders and
                investors looking to stay up-to-date on the latest market trends and make informed investment decisions.
                With its user-friendly interface and reliable data sources, stocksEyes is the go-to solution for anyone
                looking to monitor the financial markets and make informed trades.
            </p>
            <div style={{display: "flex", justifyContent: "center"}}>
                <h2>Read, Try, Test our APIs on Rapid APIs</h2>
            </div>

            <div style={{display: "flex", justifyContent: "center"}}>
                <b>(Fast & Robust, Build for Scale, No rate limits)</b>
            </div>
            <br/>
            <div style={{display: "flex", justifyContent: "center"}}>
                <a rel="noreferrer" href="https://rapidapi.com/singhaltushar903/api/stockseyes" target="_blank">
                    <img src="https://storage.googleapis.com/rapidapi-documentation/connect-on-rapidapi-light.png"
                         width="215" alt="Connect on RapidAPI"/>
                </a>
            </div>
            <br/><br/><br/>
            <h2>But I have custom Requirements</h2>
            <p>We have got you covered , get any required quota/volume and custom quote.
                Please post your requirement at <Link to={"/customRequirements"}>Custom Requirements</Link>
                &nbsp; or <Link to={"/contact"}>Contact us</Link>
            </p>
        </Information>
    );
}

export default API;
