import React from 'react';
import {Link} from "react-router-dom";

const Header = () => (
    <div style={{
        height: '50px',
        width: '100%',
        backgroundColor: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }}>
        <div style={{ color: 'white' }}>
            <Link to="/" style={{color: "white"}}><span style={{ marginRight: '20px' }}>Home</span></Link>
            <Link to="/about" style={{color: "white"}}><span style={{ marginRight: '20px' }}>About</span></Link>
            <Link to="/api" style={{color: "white"}}><span style={{ marginRight: '20px' }}>API</span></Link>
            <Link to="/contact" style={{color: "white"}}><span style={{ marginRight: '20px' }}>Contact us</span></Link>
        </div>
    </div>
);

export default Header;