import React from 'react';
import '../css/Information.css';
import '../css/OptionView.css';
import TopHeader from "./TopHeader";
import Footer from "./Footer";
import {baseBackgroundImage} from "../assets/urls";


function BaseView(props) {
    const overlayStyle = {
        backgroundColor: 'black', // The color of the overlay
        opacity: 0.6, // You can adjust the opacity as needed
    };

    return (
        <div className="image-overlay-container">
            <div className="background-image"
                 style={{backgroundImage: `url('${baseBackgroundImage})`}}></div>
            <div className="overlay" style={overlayStyle}></div>
            <div className="content">
                <div>
                    <TopHeader/>
                    {props.children}
                    <Footer/>
                </div>
            </div>
        </div>
    );
}

export default BaseView;




