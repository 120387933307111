import React, {useEffect} from 'react';
import '../css/Information.css';
import OptionView from "../components/OptionView";
import {Link, useParams} from "react-router-dom";

function GroupOptions() {
    const params = useParams();

    // set document title
    useEffect(() => {
        document.title = "Group Selection";
    }, []);

    return (
        <OptionView title="Choose the Group"
                    collectionPath={`countries/${params.country}/exchanges/${params.exchange}/groups`}>
            <div>
                <h2 style={{marginTop: "40px"}}>About Groups</h2>

                <p>The Group Selection page on our website offers a convenient and user-friendly way for users to choose the
                    stocks they want to track. By selecting a specific group, users can focus on the performance of the
                    Nifty 50, Nifty 500, or Nifty Bank stocks, providing them with a customized leaderboard tailored to
                    their investment goals. This feature makes it easy for users to stay informed on the latest trends and
                    developments in the Indian stock market. Whether you're a seasoned investor or just starting out, the
                    Group Selection page provides you with the flexibility to track the stocks that matter to you, helping
                    you make informed investment decisions. So, whether you're interested in tracking the performance of the
                    top 50 companies in India or focusing on the banking sector, our Group Selection page is the perfect
                    tool for you.</p>

                <h2>Checkout the stocks contained in each group</h2>
                <p><h3>Nifty 50 <Link to={"/group/nifty50"}><span style={{fontSize: "15px"}}>(Check stock list)</span>
                </Link></h3>
                    The Nifty 50 is a stock market index of the top 50 companies listed on the National Stock Exchange
                    of India. This index is widely used as a benchmark for the Indian stock market and is considered to
                    be a barometer of the Indian economy. The Nifty 50 is calculated using free float market
                    capitalization weighted methodology, which takes into account the level of public float of each
                    company's shares. With its diverse representation of major sectors of the Indian economy, the Nifty
                    50 provides a comprehensive view of the Indian stock market and is a popular choice for investment
                    and trading. By tracking the performance of the Nifty 50, investors can get a snapshot of the health
                    of the Indian economy and make informed investment decisions.
                </p>

                <p>
                    <h3>NIFTY BANK <Link to={"/group/bankNifty"}><span
                        style={{fontSize: "15px"}}>(Check stock list)</span> </Link></h3>
                    The Nifty Bank is a stock market index that tracks the performance of the banking sector in India.
                    The index comprises of the 12 most liquid and large banking stocks listed on the National Stock
                    Exchange of India (NSE). The Nifty Bank is a sub-index of the Nifty 50 and is widely used as a
                    benchmark for the Indian banking sector. This index reflects the overall performance of the banking
                    sector in India, which is a crucial component of the Indian economy. The Nifty Bank takes into
                    account the free float market capitalization weighted methodology, which considers the level of
                    public float of each company's shares. By tracking the Nifty Bank, investors can gain a
                    comprehensive understanding of the performance of the Indian banking sector and make informed
                    investment decisions.
                </p>

                <p>
                    <h3>NIFTY MIDCAP 50 <Link to={"/group/niftyMidCap50"}><span style={{fontSize: "15px"}}>(Check stock list)</span>
                    </Link></h3>
                    The Nifty Midcap 50 is a stock market index that tracks the performance of the mid-cap sector in
                    India. The index comprises of 50 mid-cap companies listed on the National Stock Exchange of India
                    (NSE) and is considered to be a barometer of the Indian mid-cap sector. The Nifty Midcap 50 is a
                    sub-index of the Nifty 50 and provides a comprehensive view of the mid-cap segment of the Indian
                    stock market. This index is calculated using free float market capitalization weighted methodology,
                    which takes into account the level of public float of each company's shares. The Nifty Midcap 50 is
                    a popular choice for investment and trading, as it provides investors with exposure to the growth
                    potential of the mid-cap segment in India. By tracking the Nifty Midcap 50, investors can gain a
                    snapshot of the performance of the Indian mid-cap sector and make informed investment decisions.
                </p>

                <p>
                    <h3>NIFTY NEXT 50 <Link to={"/group/niftyNext50"}><span style={{fontSize: "15px"}}>(Check stock list)</span>
                    </Link></h3>
                    The Nifty Next 50 is a stock market index that tracks the performance of the next 50 largest
                    companies listed on the National Stock Exchange of India (NSE) after the Nifty 50. The Nifty Next 50
                    provides a comprehensive view of the Indian stock market beyond the Nifty 50, offering exposure to a
                    broader range of companies. This index is calculated using free float market capitalization weighted
                    methodology, which takes into account the level of public float of each company's shares. The Nifty
                    Next 50 is a popular choice for investment and trading, as it offers exposure to a diverse range of
                    growing and dynamic companies in India. By tracking the Nifty Next 50, investors can gain a snapshot
                    of the performance of the next 50 largest companies in India and make informed investment decisions.
                </p>

                <p>
                    <h3>NIFTY SECTOR INDICES <Link to={"/group/niftySectorIndices"}><span style={{fontSize: "15px"}}>(Check stock list)</span>
                    </Link></h3>
                    Nifty Sector Indices are a set of stock market indices that track the performance of specific
                    sectors of the Indian economy. These indices provide a comprehensive view of the performance of each
                    sector and are calculated using free float market capitalization weighted methodology, which takes
                    into account the level of public float of each company's shares. The Nifty Sector Indices include
                    indices for the banking, finance, IT, FMCG, pharma, energy, and other key sectors of the Indian
                    economy. By tracking these indices, investors can gain a snapshot of the performance of each sector
                    and make informed investment decisions. The Nifty Sector Indices are widely used by traders,
                    investors, and market analysts as a benchmark for each respective sector and as a means to track
                    sectoral trends and performance.
                </p>

                <p>
                    <h3>NIFTY 500 <Link to={"/group/nifty500"}><span
                        style={{fontSize: "15px"}}>(Check stock list)</span> </Link></h3>
                    The Nifty 500 is a stock market index that tracks the performance of the top 500 companies listed on
                    the National Stock Exchange of India (NSE). This index provides a comprehensive view of the Indian
                    stock market and is considered to be a barometer of the Indian economy. The Nifty 500 is calculated
                    using free float market capitalization weighted methodology, which takes into account the level of
                    public float of each company's shares. The Nifty 500 represents a diverse range of sectors and
                    industries, including finance, IT, FMCG, pharma, energy, and more. With its broad representation of
                    the Indian stock market, the Nifty 500 is a popular choice for investment and trading. By tracking
                    the Nifty 500, investors can gain a snapshot of the performance of the top 500 companies in India
                    and make informed investment decisions.
                </p>

            </div>
        </OptionView>
    );
}

export default GroupOptions;
