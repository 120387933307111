import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App, {loader as rootLoader} from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
// import ErrorPage from "./error-page";
// import ContactTemp, {loader as contactLoader} from "./contactTemp";
import General from "./General";
import Leaderboard from "./pages/Leaderboard";
import 'antd/dist/reset.css';
import About from "./pages/About";
import Disclaimer from "./pages/Disclaimer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Contact from "./pages/Contact";
import TermsAndConditions from "./pages/TermsAndConditions";
import Home from "./pages/Home";
import LeaderBoardOptions from "./pages/LeaderboardOptions";
import CountryOptions from "./pages/CountryOptions";
import ExchangeOptions from "./pages/ExchangeOptions";
import GroupOptions from "./pages/GroupOptions";
import CandleData from "./pages/CandleData";
import ShareInsight from "./pages/ShareInsight";
import CustomRequirements from "./pages/CustomRequirements";
import ExcelOptions from "./pages/ExcelOptions";
import API from "./pages/API";
import Group from "./pages/Group";
import NotFound from "./pages/NotFound";
import API2 from "./pages/API2";
import AddBlog from "./pages/AddBlog";
import Blogs from "./pages/Blogs";
import Blog from "./pages/Blog";
import Unsubscribe from "./pages/Unsubscribe";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>
    },
    // {
    //     path: "/learn",
    //     element: <App/>,
    //     errorElement: <ErrorPage/>,
    //     loader: rootLoader,
    //     children: [
    //         {
    //             index: true,
    //             element: <h1>That tells the router to match and render this route when the user is at the parent route's
    //                 exact path, so there are no other child routes to render in the.</h1>
    //         },
    //         {
    //             path: "contacts/:contactId",
    //             element: <ContactTemp/>,
    //             loader: contactLoader,
    //         },
    //     ],
    // },
    {
        path: "/api",
        element: <API/>
    },
    {
        path: "/api2",
        element: <API2/>
    },
    {
        path: "/about",
        element: <About/>
    },
    {
        path: "/disclaimer",
        element: <Disclaimer/>
    },
    {
        path: "/privacyPolicy",
        element: <PrivacyPolicy/>
    },
    {
        path: "/contact",
        element: <Contact/>
    },
    {
        path: "/termsAndConditions",
        element: <TermsAndConditions/>
    },
    {
        path: "/shareInsight",
        element: <ShareInsight/>
    },
    {
        path: "/customRequirements",
        element: <CustomRequirements/>
    },
    {
        path: "/excelDownload",
        element: <ExcelOptions/>
    },
    {
        path: "/excelDownload/candleData/INDIA/NSE",
        element: <CandleData/>
    },
    {
        path: "/leaderboard",
        element: <LeaderBoardOptions/>
    },
    {
        path: "/blog/:blogId",
        element: <Blog/>
    },
    {
        path: "/group/:groupId",
        element: <Group/>
    },
    {
        path: "/:product/:productId",
        element: <CountryOptions/>
    },
    {
        path: "/:product/:productId/:country",
        element: <ExchangeOptions/>
    },
    {
        path: "/:product/:productId/:country/:exchange",
        element: <GroupOptions/>
    },
    {
        // http://localhost:3000/leaderboard/bullishMarubuzo/INDIA/NSE/nifty50
        path: "/leaderboard/:productId/:country/:exchange/:group",
        element: <Leaderboard/>
    },
    {
        // http://localhost:3000/leaderboard/bullishMarubuzo/INDIA/NSE/nifty50
        path: "/excelDownload/:productId/:country/:exchange/:group",
        element: <General/>
    },
    {
        path: "/addBlog",
        element: <AddBlog/>
    },
    {
        path: "/blogs",
        element: <Blogs/>
    },
    {
        path: "/unsubscribe",
        element: <Unsubscribe/>
    },
    {
        path: "*",
        element: <NotFound/>
    }


    // {
    //     path: "/:country/:exchange?/:stocks?/:group?",
    //     element: <General/>
    // },
    // {
    //     //http://localhost:3000/india/nse/stocks/nifty50/bullishMarubuzo
    //     path: "/:country/:exchange?/:type?/:group?/:pattern?",
    //     element: <Leaderboard/>
    // }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <RouterProvider router={router}/>
    //
    // <React.StrictMode>
    //     <RouterProvider router={router}/>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
