import React, {useEffect, useState} from 'react';
import {Table} from 'antd';
import '../css/LeaderBoardTable.css';
import {getLeaderboardData} from "../external/firebase";
import {capitalizeAndInsertSpaces} from "../utils/common";
import {
    // keysToEliminateFromHeader,
    specificMetricProperties,
    orderOfColumnsInHeader,
    keysToInclude
} from "../config/stockEyesConfig";
import {Select} from 'antd';

const {Option} = Select;
// const columns = [
//     {
//         title: 'Name',
//         dataIndex: 'name',
//         key: 'name',
//         align: 'center'
//     },
//     {
//         title: 'Age',
//         dataIndex: 'age',
//         key: 'age',
//         align: 'center'
//     },
//     {
//         title: 'Address',
//         dataIndex: 'address',
//         key: 'address',
//         align: 'center'
//     },
//     {
//         title: 'score',
//         dataIndex: 'score',
//         key: 'score',
//         align: 'center'
//     }
// ];
//
// const data = [
//     {
//         key: 1,
//         name: 'John Doe',
//         age: 32,
//         address: 'New York No. 1 Lake Park',
//     },
//     {
//         key: 2,
//         name: 'Jim Green',
//         age: 42,
//         address: 'London No. 1 Lake Park',
//     },
//     {
//         key: 3,
//         name: 'Joe Black',
//         age: 32,
//         address: 'Sidney No. 1 Lake Park',
//     },
// ];

const LeaderBoardTable = (props) => {

    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [timeFrame, setTimeFrame] = useState("1m");
    const [textColor, setTextColor] = useState(false);
    // const [refreshCount, setRefreshCount] = useState(0);
    const [unsubscribe, setUnsubscribe] = useState(() => () => {
    });
    const [isForeground, setIsForeground] = useState(true);
    const handleTimeFrameChange = (value) => {
        setTimeFrame(value)
    };

    // set document title
    useEffect(() => {
        document.title = props.headerText;
    }, [props.headerText]);

    // handles when the leaderboard screen goes off-screen to save on read bandwidth
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                setIsForeground(false);
                console.log("Tab is in background")
            } else {
                setIsForeground(true);
                console.log("Tab is in foreground")
            }
        };
        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    // set transition in text colour change on refresh of data
    useEffect(() => {
        setTimeout(() => {
            setTextColor(true);
        }, 1000);
    }, [data]);

    // unsubscribe from firebase listener when app goes in background
    useEffect(() => {
        if (!isForeground) {
            unsubscribe();
        }
    }, [isForeground, unsubscribe])

    // fetch data from firebase
    useEffect(() => {
        if (!isForeground) return;
        const setTableData = (data) => {
            let newData = []
            data.forEach(row => {
                let newRow = {
                    key: row.tradingSymbol,
                    ...row,
                    score: row.score * specificMetricProperties[row.metricName].multiplier
                }
                newRow[row.metricName] = row.metricValue
                newData.push(newRow)
            })
            // setRefreshCount(r => r+1);
            newData.forEach(row => row.updatedAt = row.updatedAt.toDate().toString())
            setData(newData)
            setTextColor(false)
        }
        const unsubscribeFromFirebase = getLeaderboardData(timeFrame, props.country, props.exchange, props.group, props.pattern, 10, setTableData);
        window.addEventListener('beforeunload', unsubscribeFromFirebase);
        setUnsubscribe(() => unsubscribeFromFirebase)
        return () => {
            unsubscribeFromFirebase();
            window.removeEventListener('beforeunload', unsubscribeFromFirebase);
        }
    }, [isForeground, timeFrame, props.country, props.exchange, props.type, props.group, props.pattern])

    // set headers for table
    useEffect(() => {
        if (data && data.length > 0) {
            const columns = Object.keys(data[0]).filter(key => keysToInclude.includes(key)).map(key => {
                return {
                    title: capitalizeAndInsertSpaces(key),
                    dataIndex: key,
                    key: key,
                    align: 'center',
                    render: (text, record, index) => (
                        <span
                            style={{color: textColor ? "black" : specificMetricProperties[props.pattern].dataColor}}>{text}</span>
                    )
                }
            })
            columns.sort((a, b) => orderOfColumnsInHeader[a.key] - orderOfColumnsInHeader[b.key]);
            setColumns(columns)
        }
    }, [data, textColor, props.pattern])

    return (
        <div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '10px',
                flexWrap: "wrap"
            }}>
                <div>
                    <p>Group &nbsp; </p>
                    <h1 style={{paddingRight: "60px", fontWeight: "bold"}}>{capitalizeAndInsertSpaces(props.group)}</h1>
                </div>
                <div>
                    <p style={{paddingRight: "20px", fontWeight: "bold"}}>Time Frame </p>
                    <Select defaultValue="1m" style={{width: 120}} onChange={handleTimeFrameChange}>
                        <Option value="1m">1 minute</Option>
                        <Option value="5m">5 minutes</Option>
                        <Option value="15m">15 minutes</Option>
                        <Option value="1h">1 hour</Option>
                    </Select>
                </div>
            </div>
            <Table columns={columns} dataSource={data}
                   className={`table-styled green-text ${specificMetricProperties[props.pattern].tableHeaderClass}`}
                   loading={!data || data.length === 0}/>
            {/*<p>Refresh count: {refreshCount}</p>*/}
        </div>
    );
};

export default LeaderBoardTable;
